<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("roles", pageName, pageName) }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="can($route.meta.middlewares.permission, 'create')"
            :to="{
              name: addRouteName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfRoles && listOfRoles.length"
    >
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': !editing }"
        v-for="role in listOfRoles"
        :key="role.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="editRole(role)"
          v-if="!editing"
        >
          <span class="label">
            <span class="highlight">{{ role.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`resource-type${role.id}`"
              name="user"
              :selected="selectedRoles.includes(role.id)"
              :value="role.id"
              @change="selectRole(role)"
              :disabled="!canItem(role, 'delete')"
            />
            <label :for="`resource-type${role.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              {{ role.name }}</label
            >
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{ displayLabelName("global.footer.select") }}
            </button>
          </li>
          <li class="action" v-if="selectedRoles.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" + selectedRoles.length + "/" + listOfRoles.length + ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("roles", "roles", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("roles", "roles", "delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import rolesMixin from "@/services/mixins/roles/rolex-mixin";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "Roles",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      editing: false,
      selectedRoles: []
    };
  },
  created() {
    this.fetchRoles();
  },
  computed: {
    ...mapState("roles", ["roles", "selectedRole"]),
    listOfRoles() {
      const query = this.searchQuery;
      if (this.roles && this.roles && this.roles.length) {
        if (query && query.length > 1) {
          return this.roles.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.roles;
        }
      }
      return null;
    },
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "user-roles";
      } else if (containerType === "RESOURCE") {
        return "resource-roles";
      } else if (containerType === "LEVEL") {
        return "level-roles";
      } else if (containerType === "USER_MANAGER") {
        return "user-manager-roles";
      } else if (containerType === "RECEPTION_DESK") {
        return "reception-desk-roles";
      }
      return "";
    }
  },
  methods: {
    ...mapActions("roles", ["setSelectedRole", "deleteRole"]),
    ...mapActions("pagination", ["setPaginationData"]),
    editRole(role) {
      if (this.canItem(role, "update")) {
        this.setSelectedRole(role);
        setTimeout(() => {
          this.$router.push({ name: this.editRouteName });
        }, 0);
      }
    },
    selectRole(role) {
      if (this.selectedRoles.includes(role.id)) {
        this.selectedRoles.splice(this.selectedRoles.indexOf(role.id), 1);
      } else {
        this.selectedRoles.push(role.id);
      }
    },
    setEditing() {
      this.setSelectedRole(null);
      this.editing = !this.editing;
      this.$router.push({ name: this.backLinkName });
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedRoles.length; i++) {
        await this.deleteRole(this.selectedRoles[i]);
      }

      this.processing = false;
      this.editing = false;
      this.selectedRoles = [];
      this.setPaginationData(null);
      this.setSelectedRole(null);
      this.fetchRoles();
      this.$nextTick(() => {
        this.$router.push({ name: this.backLinkName });
      });
    }
  },
  watch: {
    containerTypeId() {
      this.fetchRoles();
    }
  },
  mixins: [rolesMixin, paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
